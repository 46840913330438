<template>
  <div class="cart">
    <h3>Keranjang Belanja</h3>
    <ul class="list-group mb-3">
      <li
        v-for="item in cartItems"
        :key="item.kode"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <span class="col-4">
          {{ item.nama }} <br>
          <div class="d-flex align-items-center">
            <button 
              class="btn btn-sm btn-outline-secondary me-1"
              @click="decreaseQuantity(item.kode)"
              :disabled="item.quantity <= 1"
            >
              <i class="fas fa-minus"></i>
            </button>
            <button class="btn btn-sm btn-outline-secondary me-1">x{{ item.quantity }}</button>
            <button 
              class="btn btn-sm btn-outline-secondary ms-1"
              @click="increaseQuantity(item.kode)"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <small>{{ item.satuan }}</small>
        </span>
        <span>Harga: <br>{{ formatCurrency(item.harga) }}</span>
        <span>Total: <br>{{ formatCurrency(item.harga * item.quantity) }}</span>
        <button @click="removeItem(item.kode)" class="btn btn-danger btn-sm">
          <i class="fas fa-trash"></i>
        </button>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <span class="col-4">Total Harga:</span>
        <span></span>
        <span align="right"><b>{{ formatCurrency(totalPrice) }}</b></span>
      </li>
      <li class="list-group-item d-flex justify-content-end">
        <span>
          <button @click="showModal = true" class="btn btn-primary btn-sm">
            <i class="fas fa-shopping-cart"></i> Selesaikan Transaksi
          </button>
        </span>
      </li>
    </ul>

    <!-- Payment Modal -->
    <div v-if="showModal" class="modal fade show d-block" style="background: rgba(0,0,0,0.5);">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Pembayaran</h5>
            <button type="button" class="btn-close" @click="showModal = false"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="completeTransaction">
              <div class="mb-3">
                <label for="totalAmount" class="form-label">Total Biaya</label>
                <input
                  type="text"
                  class="form-control"
                  id="totalAmount"
                  v-model="formattedTotalPrice"
                  readonly
                />
              </div>
              <div class="mb-3">
                <label for="paymentMethod" class="form-label">Metode Pembayaran</label>
                <select class="form-select" id="paymentMethod" v-model="paymentMethod">
                  <option value="Tunai">Tunai</option>
                  <option value="Debit">Debit</option>
                  <option value="QRIS">QRIS</option>
                  <option value="Transfer">Transfer</option>
                  <option value="Hutang">Hutang</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="nominal" class="form-label">Nominal Pembayaran</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="nominalPayment"
                />
              </div>
              <div class="mb-3">
                <label for="remainingAmount" class="form-label">Sisa / Kembalian</label>
                <input
                  type="text"
                  class="form-control"
                  id="remainingAmount"
                  v-model="remainingAmountFormatted"
                  readonly
                />
              </div>
              <button type="submit" class="btn btn-success" :disabled="isLoading">
                <span v-if="isLoading">
                  <i class="fas fa-sync-alt fa-spin"></i> Memproses...
                </span>
                <span v-else>
                  Selesai Transaksi
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import axios from 'axios';

export default {
  props: ['cartItems', 'totalPrice', 'idcust'],
  data() {
    return {
      paymentMethod: 'Tunai',
      nominalPayment: 0,
      showModal: false,
      isLoading: false,
    };
  },
  computed: {
    formattedTotalPrice() {
      return this.formatCurrency(this.totalPrice);
    },
    remainingAmount() {
      const nominal = parseFloat(this.nominalPayment) || 0;
      return nominal - this.totalPrice;
    },
    remainingAmountFormatted() {
      return this.formatCurrency(this.remainingAmount);
    }
  },
  methods: {
    removeItem(kode) {
      this.$emit('remove-item', kode);
    },
    increaseQuantity(kode) {
      const item = this.cartItems.find((product) => product.kode === kode);
      if (item) {
        item.quantity += 1;
        this.updateCart();
      }
    },
    decreaseQuantity(kode) {
      const item = this.cartItems.find((product) => product.kode === kode);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
        this.updateCart();
      }
    },
    updateCart() {
      this.$emit('update-cart', this.cartItems);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    },
    async completeTransaction() {
      this.isLoading = true;
      const orderData = {
        notrk: this.$route.params.notrk,
        bayar: this.nominalPayment,
        bayar_via: this.paymentMethod,
        cart: this.cartItems.map(item => ({
          idcust: item.idcust,
          kode: item.kode,
          jumlah: item.quantity,
          satuan: item.satuan,
          harga: item.harga,
          biaya: item.harga * item.quantity
        }))
      };

      try {
        const response = await axios.post('https://km99.web.id/api/sendorder', orderData);
        if (response.data.error) {
          alert(response.data.error);
          this.isLoading = false;
          return;
        }
        if (response.data.status === 'success') {
          alert('Transaksi berhasil');
          window.open(response.data.linkinvoice, '_blank', 'noopener,noreferrer');
          window.location.href = 'https://km99.web.id/kasir/transaksi.keluar';
        }
        this.isLoading = false;
      } catch (error) {
        console.error('Error sending order:', error);
        this.isLoading = false;
      }
    }
  }
};
</script>
