<template>
  <div class="product-list">
    <div class="row g-1">
      <div class="col-lg-6">
        <label for="">Cari Produk</label>
        <select
          class="select2 form-control"
          ref="productSelect"
        ></select>
      </div>
      <div class="col-lg-6">
        <label for="">Pelanggan</label>
        <select
          class="select2 form-control"
          ref="customerSelect"
        ></select>
      </div>
    </div>
    <div v-for="product in products" :key="product.id" class="card mb-3">
      <div class="card-body p-2">
        <div class="row pb-1">
          <div class="col-md-8">
            <b class="card-title pt-1 pb-1">{{ product.nama }}</b><br>
            <p class="card-text">
              Stok : {{ product.stok }}
            </p>
          </div>
          <div class="col-md-4">
            <label>Jumlah</label>
            <input
              type="number"
              class="form-control"
              v-model.number="product.quantity"
              min="1"
              placeholder="Masukkan jumlah"
              @keyup="hargaqty(product.kode, idcust, product.quantity)"
            />
          </div>
        </div>

        <div class="btn-group w-100">
          <button
            @click="selectPrice(product, product.induk, product.harga1)"
            class="btn btn-primary btn-sm"
          >
            Harga per {{ product.induk }}<br>
            {{ formatCurrency(product.harga1) }}
          </button>
          <button
            @click="selectPrice(product, product.turunan, product.harga2)"
            class="btn btn-success btn-sm"
          >
            Harga per {{ product.turunan }}<br>
            {{ formatCurrency(product.harga2) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "select2/dist/css/select2.css";
import $ from "jquery";
import "select2";

export default {
  data() {
    return {
      searchQuery: "",
      products: [],
      customers: [],
      idcust: "1",
      selectedProduct: null,
    };
  },
  mounted() {
    this.initializeSelect2();
    this.getCustomers();
  },
  methods: {
    async cariproduk() {
      try {
        const response = await axios.get("https://km99.web.id/api/produk", {
          params: {
            search: this.selectedProduct,
            idcust: this.idcust,
          },
        });

        this.products = response.data.map((product) => ({
          ...product,
          quantity: 1, // Set default quantity to 1
        }));
      } catch (error) {
        console.error("Gagal mengambil produk:", error);
      }
    },
    async getCustomers() {
      try {
        const response = await axios.get("https://km99.web.id/api/pelanggan");
        this.customers = response.data;
        this.initializeSelect2();
      } catch (error) {
        console.error("Gagal mengambil pelanggan:", error);
      }
    },
    initializeSelect2() {
      // Inisialisasi Select2 untuk pelanggan
      $(this.$refs.customerSelect).select2({
        data: this.customers.map((customer) => ({
          id: customer.id,
          text: `${customer.kodemember} - ${customer.nama}`,
        })),
      });

      $(this.$refs.customerSelect).on("change", () => {
        this.idcust = $(this.$refs.customerSelect).val();
        this.cariproduk(); // Panggil ulang cariproduk saat pelanggan berubah
      });

      // Inisialisasi Select2 untuk produk
      $(this.$refs.productSelect).select2({
        ajax: {
          url: "https://km99.web.id/api/produk",
          dataType: "json",
          delay: 250,
          data: (params) => ({
            search: params.term,
            idcust: this.idcust,
          }),
          processResults: (data) => ({
            results: data.map((product) => ({
              id: product.kode,
              text: product.nama,
            })),
          }),
          cache: true,
        },
        minimumInputLength: 1,
      });

      $(this.$refs.productSelect).on("change", () => {
        this.selectedProduct = $(this.$refs.productSelect).val();
        this.cariproduk();
      });
    },
    async hargaqty(kode, idcust, qty) {
      try {
        const response = await axios.get("https://km99.web.id/api/produk", {
          params: {
            search: kode,
            idcust: idcust,
            qty: qty,
          },
        });

        const updatedProduct = response.data[0];
        this.products = this.products.map((product) => {
          if (product.kode === kode) {
            return {
              ...product,
              harga1: updatedProduct.harga1,
              harga2: updatedProduct.harga2,
              quantity: qty,
            };
          }
          return product;
        });
      } catch (error) {
        console.error("Gagal memperbarui harga:", error);
      }
    },
    selectPrice(product, satuan, harga) {
      const selectedQuantity = product.quantity || 1;

      const selectedProduct = {
        kode: product.kode,
        nama: product.nama,
        satuan,
        harga,
        quantity: selectedQuantity,
        idcust: this.idcust,
      };

      this.addToCart(selectedProduct);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    },
    addToCart(product) {
      this.$emit("add-to-cart", product);
    },
  },
};
</script>

<style scoped>
/* Custom styles for product list */
</style>
